<template>
	<div class='audio_works'>
		<creation-stat :statInfo="statInfo"></creation-stat>

		<div class="mt20 sec_wrap p20">
			<div class="top-box">
				<div class="tab">
					<div class="tabs_item" :class="status == item.value ? 'active' : ''" v-for="(item, index) in tabs"
						:key="index" @click="tabChange(item)">
						<div class="txt">{{ item.label }}</div>
						<div class="bar"></div>
					</div>
				</div>
				<div class="search_box">
					<div class="flex_box">
						<div class="form_item">
							<div class="label">作品分类</div>
							<el-select class="form_val" size="small" v-model="params.label" placeholder="请选择作品分类"
								@change="search">
								<el-option v-for="item in cate_list" :key="item.id" :label="item.name"
									:value="item.id" :clearable="true">
								</el-option>
							</el-select>
						</div>
						<div class="form_item ml10">
							<div class="label">筛选</div>
							<el-select class="form_val" size="small" v-model="params.time_select" placeholder="请选择"
								@change="search">
								<el-option v-for="item in time_list" :key="item.value" :label="item.label"
									:value="item.value" :clearable="true">
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
			</div>
			<div class="list_con p20">
				<audio-list v-if="list.length" :list="list" @refreshBtn="getList" />
				<empty-data v-else v-loading="loading" img='no_img_b.png' text='这里什么都没有哦~' :is_audio="true"></empty-data>

			</div>
			<div class="page_box mt20">
				<paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
					@currentChange='currentChangeBtn'></paging-page>
			</div>
		</div>
	</div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import CreationStat from '../components/creationStat/index'
import audioList from '../components/creationList/audio'
export default {
	components: { EmptyData, PagingPage, CreationStat, audioList },
	props: {},
	data() {
		return {
			statInfo: {},
			tabs: [
				{
					label: '全部',
					value: '',
				},
				{
					label: '创作中',
					value: 1,
				},
				{
					label: '合成中',
					value: 3,
				},
				{
					label: '排队中',
					value: 2,
				},
				{
					label: '创作失败',
					value: 5,
				},
				{
					label: '合成成功',
					value: 4,
				},
			],
			status: '',
			cate_list: [],
			params: {},
			time_list: [],
			status_list: [],
			page: 1,
			limit: 10,
			total: 0,
			list: [],
			loading: false,
		}
	},
	computed: {},
	watch: {},
	created() {
		this.getStat()
		this.getList()

	},
	mounted() { },
	methods: {
		tabChange(item) {
			this.status = item.value
			this.resetFrom()
		},
		search() {
			this.page = 1
			this.list = []
			this.total = 0
			this.getList()
		},
		resetFrom() {
			this.params.label = ''
			this.params.time_select = ''
			this.search()
		},
		getList() {
			const data = {
				page: this.page,
				limit: this.limit,
				status: this.status,
				...this.params,
			}
			this.loading = true
			this.$creationApi.getAudioList(data).then(res => {
				this.loading = false
				if (res.code == 1000) {
					this.list = res.result.data
					this.total = res.result.total
				} else {
					this.$errMsg(res.message)
				}
			})

		},
		sizeChangeBtn(val) {
			this.limit = val
			this.page = 1
			this.getList();
		},
		currentChangeBtn(val) {
			this.page = val;
			this.getList();
		},
		getStat() {
            this.$creationApi.getAudioStat().then(res => {
				if (res.code == 1000) {
                    let statInfo = res.result
					this.statInfo = {
						creation: statInfo.in_creation,
						synthesis: statInfo.in_synthesis,
						in_line: statInfo.in_line,
						incomplete: statInfo.incomplete,
						completed: statInfo.completed,
						failed: statInfo.failed,
					}
					this.cate_list = statInfo.labels
					this.status_list = statInfo.status
					this.time_list = statInfo.time_select
				} else {
					this.$errMsg(res.message)
				}
			})
        },
	},
}
</script>

<style lang='scss' scoped>
@import '../creation.scss';

.audio_works {
	min-width: 1774px;

	.top-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}</style>